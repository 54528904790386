//Так приятней :)
window.log = function(param){
    console.log(param);
};
$('.sh-menu').on('click', function(){
	$('.menu').toggleClass('wmin');
	$('.submenu').hide();
})
$('.sub').on('click', function () {
	var sm = $(this).siblings('.submenu')
	if ($('.menu').hasClass('wmin')) {
		$('.menu').removeClass('wmin');
		sm.show();
	}else{
		if (sm.is(":hidden")){
			sm.slideDown('fast');
		} else {
		    sm.slideUp('fast');
		}
	}
})
$('.owl-carousel').owlCarousel({
    loop:true,
    autoplay:true,
    autoplayHoverPause:true,
    slideSpeed :900,
    nav:true,
    pagination:true,
    navText : ["<i class='ion-chevron-left'></i>","<i class='ion-chevron-right'></i>"],
    margin:0,
    items: 1
})